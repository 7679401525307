import { request } from '@/common/request';

const prefix = '';
export const downloadFile = (fileId: string) => {
  const url = `${prefix}/cos/download`;
  return request.post(url, { key: fileId });
};
// @todo 上传文件
// @author Herisson Pan
// @date 2022/7/7
export function uploadFile(data: any) {
  return request({
    url: '/cos/upload',
    method: 'post',
    data,
  });
}

// @todo 副文本上传文件
// @author Herisson Pan
// @date 2022/7/7
export function cosResourceUploadFile(data: any) {
  return request({
    url: '/cos/resource/upload',
    method: 'post',
    data,
  });
}

// 机构列表
export const getAgencyList = (id: string) => {
  const url = '/agency/getAgencyList';
  return request.post<any, any>(url, { id });
};
// 医院列表
export const getHospitalList = (params: any) => {
  const url = '/hospital/listPage';
  return request.post<any, any>(url, params);
};
// 科室列表
export const getDepartmentList = (params: any) => {
  const url = '/department/listPage';
  return request.post<any, any>(url, params);
};
// 职称列表
export const getJobTitleList = (params: any) => {
  const url = '/jobTitle/listPage';
  return request.post<any, any>(url, params);
};
// 获取账号登录信息
export const getDoctorAccount = (params: any) => {
  const url = '/agency/getUser';
  return request.post<any, any>(url, params);
};

export default {
  downloadFile,
};
