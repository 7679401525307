import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, Space, Spin } from '@sinohealth/butterfly-ui-components';
import styles from './index.less';
import { useMenuConfig } from '@/hooks/useMenu';
import useCurrentRoute from '@/hooks/useCurrentRoute';
import UserInfo from '@/pages/home/components/UserInfo';
import Team from '@/pages/home/components/Team';
import usePermission from '@/hooks/usePermission';
import useCurrentLoginUserInfo from '@/hooks/useCurrentLoginUserInfo';
import Navbar from '@/pages/home/components/Navbar';

const logoImg = require('@/assets/images/common/logo.png');

const { Header, Sider, Content } = Layout;

const Home: React.FC = () => {
  const [userInfo] = useCurrentLoginUserInfo();
  const [collapsed, setCollapsed] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const navigate = useNavigate();
  const { menuData, defaultOpenKeys, defaultSelectedKeys } = useMenuConfig();
  const permission = usePermission('commonLayout');

  const fullPage = () => {
    setFullscreen(!fullscreen);
    if (fullscreen) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  const handleMenuClick = (e: any) => {
    navigate(e.key);
  };

  if (!userInfo) {
    return (
      <div style={{ display: 'flex', height: '100vh' }}>
        <Spin size="large" style={{ margin: 'auto' }} />
      </div>
    );
  }
  // @ts-ignore
  return (
    <Layout className={styles.layout}>
      <Sider className={styles.sider} theme="light" trigger={null} collapsible collapsed={collapsed}>
        <div className={styles.logo}>
          <div style={{ width: !collapsed ? '200px' : 'auto' }}>
            <img src={logoImg} style={{ height: !collapsed ? '21px' : '14px', marginTop: !collapsed ? '0' : '7px' }} alt="" />
            {
              !collapsed && <span>管理平台</span>
            }
          </div>
        </div>
        <Menu
          onClick={handleMenuClick}
          key={defaultSelectedKeys[0] + defaultOpenKeys[0]}
          mode="inline"
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={defaultOpenKeys}
          items={menuData}
        />
      </Sider>
      <Layout>
        <Header className={styles.header}>
          <div className={styles.pageInfo}>
            <Button
              className={styles.collapsedBtn}
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
            />
            <Navbar />
          </div>
          <div className={styles.toolbar}>
            <Space>
              {
                permission.team && <Team />
              }
              <UserInfo />
              <Button
                className={styles.fullBtn}
                type="text"
                icon={!fullscreen ? <FullscreenOutlined /> : <FullscreenExitOutlined />}
                onClick={() => fullPage()}
              />
            </Space>
          </div>
        </Header>
        <Content className={styles.content}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default Home;
