/*
 * @Description: 讲稿演示-头部
 * @Author: likaifeng
 * @Date: 2023-11-30 15:47:43
 * @LastEditTime: 2023-12-05 16:56:01
 * @LastEditors: likaifeng
 */
import { useDoctorDetail } from '@/pages/speechLecture/hook/make';
import { speechFilterMakeDataAtom, speechFontSizeAtom } from '@/store/lecture';
import { Select } from '@sinohealth/butterfly-ui-components';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import Screenfull from '../Screenfull';
import SpeechStopWatch from '../SpeechStopWatch';
import styles from './index.less';

/**
 * @description: 讲稿演示-头部
 * @author: likaifeng
 * @param {*} props
 * @return {*}
 * @Date: 2023-12-04 14:26:45
 */
const SpeechHeader: React.FC<any> = (props) => {
  const { title } = props;

  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);
  const [speechFontSize, setSpeechFontSize] = useRecoilState(speechFontSizeAtom);

  const { doctorDetail } = useDoctorDetail(speechFilterMakeData?.baseInfo?.doctorId);

  /**
   * @description: 切换字体大小
   * @author: likaifeng
   * @param {any} e
   * @return {*}
   * @Date: 2023-11-30 20:20:36
   */
  const handleSelectFontSize = (e: any) => {
    window.document.documentElement.setAttribute('data-size', e);
    setSpeechFontSize(e);
  };

  return (
    <header className={styles['speech-header']}>
      {/* <h1 className={styles['speech-logo']}>中惠医疗科技有限公司</h1> */}
      <div className={styles['header-container']}>
        <div className={styles['header-title']}>
          <h2 className={styles['speech-title']}>{title}</h2>
          <div className={styles['speech-doctor']}>
            医生：{doctorDetail?.name}（{doctorDetail?.hospitalName} {doctorDetail?.departmentName}{' '}
            {doctorDetail?.jobTitle}）
          </div>
        </div>
        <div className={`${styles['header-tools']} ${styles['tools-width']}`}>
          {/* <speech-stop-watch
        :is-show-stopwatch="isShowStopwatch"
        @getDataToParentStopWatch="handleSetDataToParentStopWatch"
      /> */}
          <SpeechStopWatch />
          <div className={styles['font-size']}>
            <span className={styles['font-label']}>字体大小</span>
            <Select
              className={styles['font-select']}
              value={speechFontSize}
              onChange={handleSelectFontSize}
              options={[
                { value: 1, label: '小号' },
                { value: 2, label: '中号' },
                { value: 3, label: '大号' },
              ]}
            />
          </div>
          <Screenfull />
        </div>
      </div>
    </header>
  );
};

export default SpeechHeader;
