import DataBase from '@/pages/dataBase';
import Preview from '@/pages/speechLecture/preview';
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Home from '../pages/home';
import Login from '../pages/user/login';
import UserProtocol from '../pages/user/login/userProtocol';
import NoFind from '../pages/user/noFind';
import PersonalCenter from '../pages/user/personalCenter';

// 懒加载只能针对挂载在Home组件下的组件，因为Suspense组件放在Home中
const Index = lazy(() => import('../pages/index'));
const PersonalUserInfo = lazy(() => import('../pages/personal/usercenter'));
const PatientList = lazy(() => import('../pages/patient/list'));
const PatientDetail = lazy(() => import('../pages/patient/detail'));
/* ----- MDT团队 ------- */
const MdtTeam = lazy(() => import('../pages/mdtTeam'));
const MdtDetail = lazy(() => import('../pages/mdtTeam/mdtDetail'));
/* ----- 会诊中心 ------- */
const ConsultationCenter = lazy(() => import('../pages/consultationCenter'));
const ConsultationDetail = lazy(() => import('../pages/consultationCenter/detail'));
const ConsultationCreate = lazy(() => import('../pages/consultationCenter/create'));
/* ----- 病例库 ------- */
const CaseHistory = lazy(() => import('../pages/caseHistory'));
const ShareCaseHistory = lazy(() => import('../pages/caseHistory/share'));
const ConsultatDetail = lazy(() => import('../pages/caseHistory/consultatDetail'));
/* ----- 会诊讲稿 ------- */
const SpeechLecture = lazy(() => import('../pages/speechLecture'));
const Making = lazy(() => import('../pages/speechLecture/making'));
/* ----- 合同 ------- */
const ContractVerifica = lazy(() => import('../pages/contract/verification'));
const ContractTemplate = lazy(() => import('../pages/contract/template'));
const ContractPreview = lazy(() => import('../pages/contract/preview'));

// 系统管理
const AccountNumber = lazy(() => import('../pages/sysAdmin/accountNumber'));
const RoleAdmin = lazy(() => import('../pages/sysAdmin/roleAdmin'));
const MenuAdmin = lazy(() => import('../pages/sysAdmin/menuAdmin'));
// 主数据管理
const LabelAdmin = lazy(() => import('../pages/masterDataManagement/labelAdmin'));
const RegionAdmin = lazy(() => import('../pages/masterDataManagement/regionAdmin'));
const HospitalAdmin = lazy(() => import('../pages/masterDataManagement/hospitalAdmin'));
const DepartmentAdmin = lazy(() => import('../pages/masterDataManagement/departmentAdmin'));
const JobTitleAdmin = lazy(() => import('../pages/masterDataManagement/jobTitleAdmin'));
const BIConfiguration = lazy(() => import('../pages/bIModule/bIConfiguration'));
// 申请管理
const ConsultationApply = lazy(() => import('../pages/applyAdmin/consultationApply'));
const CooperationApply = lazy(() => import('../pages/applyAdmin/cooperationApply'));
// 机构管理
const InstitutionAdmin = lazy(() => import('../pages/organizationalManagement/institutionAdmin'));
const InstitutionalDetails = lazy(
  () => import('../pages/organizationalManagement/institutionalDetails'),
);

// 医生管理
const DoctorList = lazy(() => import('../pages/doctor/list'));
const DoctorDetail = lazy(() => import('../pages/doctor/detail'));
const DoctorInfoList = lazy(() => import('../pages/doctor/infoList'));
const DoctorInfoDetail = lazy(() => import('../pages/doctor/infoDetail'));

// 医助管理
const DoctorAssistantList = lazy(() => import('../pages/doctorAssistant/list'));
const DoctorAssistantDetail = lazy(() => import('../pages/doctorAssistant/detail'));

// 公众号管理
const OfficialList = lazy(() => import('../pages/officialAccount/officialList'));
const AllianceDetails = lazy(() => import('../pages/officialAccount/allianceDetails'));
const EditorialAlliance = lazy(() => import('../pages/officialAccount/editorialAlliance'));
const CreateTeam = lazy(() => import('../pages/officialAccount/createTeam'));
const CreateActivity = lazy(() => import('../pages/officialAccount/createActivity'));


export type routerConfigItem = {
  path: string;
  name?: string;
  code?: string;
  element?: React.ReactNode;
  hideInMenu?: boolean;
  children?: routerConfigItem[];
  meta?: any;
};

export const baseRouterConfig = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/login/user-protocol',
    element: <UserProtocol />,
  },
  {
    path: '*',
    element: <NoFind />,
  },
];
const routerConfig: routerConfigItem[] = [
  {
    path: '/',
    element: <Home />,
    children: [
      {
        path: '/',
        element: <Navigate to="/index" replace />,
      },
      {
        path: '/index',
        name: '我的首页',
        element: <Index />,
      },
      {
        path: '/personal/userinfo',
        name: '个人中心',
        element: <PersonalUserInfo />,
      },
      {
        path: '/personal-center/index',
        name: '个人中心',
        element: <PersonalCenter />,
        code: 'PersonalCenter',
      },
      {
        path: '/patient/list',
        name: '患者列表',
        element: <PatientList />,
        code: 'PatientManageList',
      },
      {
        path: '/patient/detail',
        name: '患者详情',
        element: <PatientDetail />,
      },
      // mdt团队
      {
        path: '/mdt-team/index',
        name: 'MDT团队',
        element: <MdtTeam />,
        code: 'MdtTeam',
      },
      {
        path: '/mdt-team/detail',
        name: '团队详情',
        element: <MdtDetail />,
        code: 'MdtDetail',
      },
      // 会诊中心
      {
        path: '/consultation-center/index',
        name: '会诊中心',
        element: <ConsultationCenter />,
        code: 'ConsultationCenterList',
      },
      {
        path: '/consultation-center/detail',
        name: '会诊详情',
        element: <ConsultationDetail />,
        code: 'ConsultationDetail',
      },
      {
        path: '/consultation-center/create',
        name: '创建会诊',
        element: <ConsultationCreate />,
        code: 'ConsultationCreate',
      },
      /* ----- 病例库 ------- */
      {
        path: '/case-history/index',
        name: '病例库',
        element: <CaseHistory />,
        code: 'CaseHistoryList',
      },
      {
        path: '/case-history/share',
        name: '共享病例库',
        element: <ShareCaseHistory />,
        code: 'ShareCaseHistoryList',
      },
      {
        path: '/case-history/consultat-detail',
        name: '会诊详情',
        element: <ConsultatDetail />,
        code: 'ShareCaseConsultatDetail',
      },
      /* ----- 会诊讲稿 ------- */
      {
        path: '/speech-lecture/index',
        name: '会诊讲稿',
        element: <SpeechLecture />,
        code: 'SpeechLectureList',
      },
      {
        path: '/speech-lecture/making',
        name: '制作讲稿',
        element: <Making />,
        code: 'SpeechMakingList',
      },
      /* ----- 合同 ------- */
      {
        path: '/contract/verification',
        name: '合同审核',
        element: <ContractVerifica />,
        code: 'ContractVerificaList',
      },
      {
        path: '/contract/template',
        name: '合同模板',
        element: <ContractTemplate />,
        code: 'ContractTemplateList',
      },
      {
        path: '/contract/preview',
        name: '合同模板',
        element: <ContractPreview />,
        code: 'ContractPreview',
      },
      {
        path: '/sys/accountNumber',
        name: '账号管理',
        element: <AccountNumber />,
        code: 'AccountNumber',
      },
      {
        path: '/sys/roleAdmin',
        name: '角色管理',
        element: <RoleAdmin />,
        code: 'RoleAdmin',
      },
      {
        path: '/sys/menuAdmin',
        name: '菜单管理',
        element: <MenuAdmin />,
        code: 'MenuAdmin',
      },
      {
        path: '/masterDataManagement',
        name: '主数据管理',
        code: 'MasterDataManagement',
      },
      {
        path: '/masterDataManagement/labelAdmin',
        name: '系统标签管理',
        element: <LabelAdmin />,
        code: 'LabelAdmin',
      },
      {
        path: '/masterDataManagement/regionAdmin',
        name: '区域管理',
        element: <RegionAdmin />,
        code: 'RegionAdmin',
      },
      {
        path: '/masterDataManagement/hospitalAdmin',
        name: '医院管理',
        element: <HospitalAdmin />,
        code: 'HospitalAdmin',
      },
      {
        path: '/masterDataManagement/departmentAdmin',
        name: '科室管理',
        element: <DepartmentAdmin />,
        code: 'DepartmentAdmin',
      },
      {
        path: '/masterDataManagement/jobTitleAdmin',
        name: '职称管理',
        element: <JobTitleAdmin />,
        code: 'JobTitleAdmin',
      },

      {
        path: '/bIModule/bIConfiguration',
        name: 'BI配置',
        element: <BIConfiguration />,
        code: 'BIConfiguration',
      },

      {
        path: '/applyAdmin/consultationApply',
        name: '会诊申请',
        element: <ConsultationApply />,
        code: 'ConsultationApply',
      },
      {
        path: '/applyAdmin/cooperationApply',
        name: '合作申请',
        element: <CooperationApply />,
        code: 'CooperationApply',
      },
      {
        path: '/organizationalManagement/institutionAdmin',
        name: '机构管理',
        element: <InstitutionAdmin />,
        code: 'InstitutionAdmin',
      },
      {
        path: '/organizationalManagement/institutionalDetails',
        name: '机构详情',
        element: <InstitutionalDetails />,
        code: 'InstitutionalDetails',
      },

      {
        path: '/officialAccount/officialList',
        name: '公众号网站管理',
        element: <OfficialList />,
        code: 'OfficialList',
      },
      {
        path: '/doctor/list',
        name: '医生管理',
        element: <DoctorList />,
        code: 'DoctorList',
      },
      {
        path: '/officialAccount/allianceDetails',
        name: '联盟详情',
        element: <AllianceDetails />,
        code: 'AllianceDetailsNew',
        hideInMenu: true,
      },
      {
        path: '/officialAccount/editorialAlliance',
        name: '编辑联盟',
        element: <EditorialAlliance />,
        code: 'EditorialAlliance',
        hideInMenu: true,
      },
      {
        path: '/officialAccount/createTeam',
        name: '编辑团队',
        element: <CreateTeam />,
        code: 'CreateTeam',
        hideInMenu: true,
      },
      {
        path: '/officialAccount/createActivity',
        name: '编辑活动',
        element: <CreateActivity />,
        code: 'CreateActivity',
        hideInMenu: true,
      },
      {
        path: '/doctor/detail',
        name: '医生详情',
        element: <DoctorDetail />,
      },
      {
        path: '/doctor/info/list',
        name: '医生信息库',
        element: <DoctorInfoList />,
        code: 'DoctorInfoList',
      },
      {
        path: '/doctor/info/detail',
        name: '医生信息库-详情',
        element: <DoctorInfoDetail />,
        code: 'DoctorInfoList',
      },
      {
        path: '/doctor/assistant/list',
        name: '医助管理',
        element: <DoctorAssistantList />,
        code: 'DoctorAssistantList',
      },
      {
        path: '/doctor/assistant/detail',
        name: '医助管理-详情',
        element: <DoctorAssistantDetail />,
      },
    ],
  },
  {
    path: '/speech-lecture/preview',
    name: '讲稿预览',
    element: <Preview />,
    code: 'SpeechMakingList',
    hideInMenu: true,
  },
  {
    path: '/dataBase',
    name: '数据统计',
    element: <DataBase />,
    code: 'DataBase',
    hideInMenu: true,
  },
  ...baseRouterConfig,
];

export default routerConfig;
