import React, { useState, useEffect } from 'react';
import { Image as AntdImage } from '@sinohealth/butterfly-ui-components';
import { download } from '@/services/index';

const cacheKey = 'imgCache';
const imgCacheStr = window.sessionStorage.getItem(cacheKey);
const imgCache = imgCacheStr && imgCacheStr !== 'null' ? JSON.parse(imgCacheStr) : {};

export const useFetchImageData = (rootSrc:any = '') => {
  const [src, setSrc] = useState<any>(rootSrc);
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    if (src && (typeof src === 'string') && src.indexOf('http') === -1 && src.indexOf('static') === -1) {
      fetchImageData()
        .catch(() => {
          // 失败后查多一次，接口不是很稳定
          fetchImageData();
        });
    } else {
      setImageUrl(src);
    }
  }, [src]);
  const fetchImageData = () => {
    // if (imgCache[src]) {
    //   setImageUrl(imgCache[src]);
    //   return Promise.resolve();
    // }
    return download(src)
      .then((res: any) => {
        setImageUrl(res);
        // imgCache[src] = res;
        // window.sessionStorage.setItem(cacheKey, JSON.stringify(imgCache));
      });
  };
  return { imageUrl, setSrc };
};

const Image = (props: any) => {
  const { src, onLoadedImgData, preview = false, isVideo, ...otherProps } = props;
  const { imageUrl, setSrc } = useFetchImageData(src);

  useEffect(() => {
    setSrc(src);
  }, [src]);

  useEffect(() => {
    onLoadedImgData && imageUrl && onLoadedImgData(imageUrl);
  }, [imageUrl]);

  if (!imageUrl) {
    return null;
  }
  if (isVideo) {
    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video src={imageUrl} controls {...otherProps} />
    );
  }
  return <AntdImage key={src} preview={preview} src={imageUrl} {...otherProps} />;
};

export default Image;
