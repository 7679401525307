/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/*
 * @Description: 讲稿演示-主要内容-内容展示组件
 * @Author: likaifeng
 * @Date: 2023-11-30 16:36:37
 * @LastEditTime: 2023-12-20 10:15:42
 * @LastEditors: likaifeng
 */
import className from 'classnames';
import React, { useState } from 'react';
import SpeechCompareModel from '../SpeechCompareModel';
import SpeechFile from '../SpeechFile';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-内容展示组件
 * @author: likaifeng
 * @param {*} props
 * @return {*}
 * @Date: 2023-12-04 14:26:03
 */
const SpeechField: React.FC<any> = (props) => {
  const {
    style,
    border,
    color, // 是否深色
    title,
    compareTitle,
    description,
    imagesSource, // 图片数据 对象数组 [{ type: 'image', url: '' },{ type: 'video', url: '' }]
    imageStyle,
    zhWedcmTask,
    dicomId,
    type = 'TEXT', // TEXT IMAGE BUTTON OTHER COMPARE
  } = props;

  const [compare, setCompare] = useState<any[]>([]);

  /**
   * @description: 查看 dicom 影像
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-10-31 14:19:37
   */
  const handleViewDicom = () => {
    if (dicomId !== -1) {
      // const query = `id=${dicomId}`;
      // window.open(`/#//patientManagement/dicom?${query}`);
      window.open(zhWedcmTask.openPath);
    }
  };

  return description || (imagesSource && imagesSource.length) || props.children ? (
    <div
      className={className(
        styles['field-item'],
        border && styles['field-border'],
        type === 'TEXT' && styles['field-text'],
        type === 'BUTTON' && styles['field-button'],
        type === 'IMAGE' && styles['field-image'],
        type === 'COMPARE' && styles['field-image'],
        type === 'OTHER' && styles['field-image'],
      )}
      style={style}
    >
      {/* 标题 */}
      {title ? (
        <div className={styles['label-wrapper']}>
          <div className={className(styles['field-label'], color && styles['deep-label'])}>
            {title}
          </div>
        </div>
      ) : null}
      {/* 文本类型内容 */}
      {type === 'TEXT' ? <div className={styles['field-desc']}>{description}</div> : null}
      {/* 按钮类型内容 description 按钮描述 */}
      {type === 'BUTTON' ? (
        <div className={styles['desc-button']} onClick={handleViewDicom}>
          <i className="el-icon-search" />
          {description}
        </div>
      ) : null}

      {/* 图片类型内容 */}
      {type === 'IMAGE' ? (
        <div className={styles['images-container']}>
          {imagesSource &&
            imagesSource?.map((el: any, index: number) => (
              <SpeechFile
                key={`${el.key}_${index}`}
                className={className(styles.images, el.type === 'video' && styles['video-label'])}
                type={el.type}
                url={el.key}
              />
            ))}
          {imagesSource && imagesSource?.length ? null : (
            <div className={styles['no-images']}>暂无图片数据</div>
          )}
        </div>
      ) : null}

      {/* 对比图 - 图片类型内容 */}
      {type === 'COMPARE' ? (
        <div className={styles['images-container']}>
          {imagesSource &&
            imagesSource?.map((el: any, index: number) => (
              <SpeechFile
                key={`${el.key}_${index}`}
                preview={false}
                className={className(styles.images, el.type === 'video' && styles['video-label'])}
                type={el.type}
                url={el.key}
                onClick={() => setCompare(imagesSource)}
              />
            ))}

          {imagesSource && imagesSource?.length ? null : (
            <div className={styles['no-images']}>暂无图片数据</div>
          )}
        </div>
      ) : null}
      {/* 其他类型 */}
      {type === 'OTHER' ? props.children : null}
      {/* 对比图查看 */}
      <SpeechCompareModel images={compare} name={compareTitle} onClose={() => setCompare([])} />
    </div>
  ) : null;
};

export default SpeechField;
