import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import { Panzoom } from '@fancyapps/ui/dist/panzoom/panzoom.esm.js';
import '@fancyapps/ui/dist/panzoom/panzoom.css';
import { Toolbar } from '@fancyapps/ui/dist/panzoom/panzoom.toolbar.esm.js';
import '@fancyapps/ui/dist/panzoom/panzoom.toolbar.css';
import { useFetchImageData } from '@/components/Image';
import styles from './index.less';

// 文档地址：https://fancyapps.com/panzoom/
const ImageZoom = (props: any) => {
  const { src } = props;
  const [zoom, setZoom] = useState();
  const { imageUrl } = useFetchImageData(src);
  const imgDom = useRef<any>();
  useEffect(() => {
    if (imgDom.current && imageUrl && !zoom) {
      const container = imgDom.current;
      const options = {
        click: 'toggleCover',
        Toolbar: {
          display: ['zoomIn', 'zoomOut'],
        },
        maxScale: 10,
      };
      // eslint-disable-next-line no-new
      const z = new Panzoom(container, options, { Toolbar });
      setZoom(z);
    }
  }, [imgDom, imageUrl]);
  return (
    <div key={src} className={[styles.imgZoom, 'f-panzoom'].join(' ')} ref={imgDom}>
      <img src={imageUrl} />
    </div>
  );
};

export default ImageZoom;
