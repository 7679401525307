/*
 * @Description: 个人中心
 * @Author: likaifeng
 * @Date: 2023-11-17 14:03:50
 * @LastEditTime: 2023-11-17 14:04:12
 * @LastEditors: likaifeng
 */
import React from 'react';
import styles from './index.less';

/**
 * @description: 个人中心
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-11-17 14:03:56
 */
const personalCenter: React.FC = () => {
  return <div className={styles['personal-center']}>personalCenter</div>;
};

export default personalCenter;
