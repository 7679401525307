/* eslint-disable no-restricted-globals */
import moment from 'moment';
import axios from 'axios';
import { message } from '@sinohealth/butterfly-ui-components';
// import { baseURL } from '@/config/base';
import { getLocalStorage } from './cookies';

const { NODE_ENV } = process.env;
export const isDev = NODE_ENV === 'development';
const DATE_FORMAT = 'YYYY-MM-DD';
/**
 * 生成随机的uuid
 */
export const getUuid = (): string =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-mixed-operators,no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

/**
 * 生成随机len位数字
 * @param len 长度
 * @param date
 * @returns
 */
export const randomLenNum = (len: number, date?: boolean) => {
  let random = '';
  random = Math.ceil(Math.random() * 100000000000000)
    .toString()
    .substr(0, len || 4);
  if (date) random += Date.now();
  return random;
};

export function getBase64(img: any, callback: any) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

export function getUserInfoFromIdCard(IdCard: string) {
  const birthDay = moment(IdCard.substring(6, 14), 'YYYYMMDD');
  const sex = window.parseInt(IdCard.substr(16, 1)) % 2 === 1 ? 'MALE' : 'FEMALE';
  const age = moment().diff(birthDay, 'year');
  return {
    birthDay,
    sex,
    age,
  };
}

export function handelOptions(obj: any) {
  if (!obj) return [];
  const options = Object.keys(obj).map((key: any) => {
    return { label: obj[key].name, value: obj[key].code };
  });
  return options;
}

export function handleDicToObj(dictArr: any) {
  const newDictObj: any = {};
  Object.keys(dictArr).forEach((key) => {
    newDictObj[key] = {};
    dictArr[key].forEach((item: any) => {
      newDictObj[key][item.code] = item.name;
    });
  });
  return newDictObj;
}

export function previewFile(fileId: string) {
  const baseURL =
    NODE_ENV === 'development' ? 'https://backend-hccm-dev.zmnyun.cn' : window.location.origin;
  return `${baseURL}/cs/file/preview/${fileId}`;
}

const downloadPdf = (url: string) => {
  return axios({
    method: 'get',
    url,
    responseType: 'blob',
  }).then((res) => {
    const blob = new Blob([res.data], {
      type: 'application/pdf',
    });
    return window.URL.createObjectURL(blob);
  });
};
export const downloadFile = async (oldUrl: string, fileName: string = '') => {
  let url = oldUrl;
  const isCanPreviewFile = (oldUrl + fileName).indexOf('.pdf') > -1;
  if (isCanPreviewFile) {
    url = await downloadPdf(url);
  }
  const link = document.createElement('a');
  link.style.display = 'none';
  if (fileName) {
    const fileNameArr = url.split('/');
    link.setAttribute('download', fileName || fileNameArr[fileNameArr.length - 1]);
    link.setAttribute('target', '_blank');
  }
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

/**
 * 格式化金额
 * @param num 金额
 * @param accuracy 小数点数
 * @param type 类型
 * @param complement
 * @returns
 */
export const toFixed = (
  num?: string | number,
  accuracy: number = 2,
  type = 'round',
  complement = true,
) => {
  if (num === '' || num === null) {
    return '';
  }
  const numTem = isNaN(Number(num)) ? 0 : Number(num);
  let decimal = isNaN(Number(accuracy)) ? 0 : Math.ceil(Number(accuracy));

  if (decimal < 0) {
    decimal = 0;
  }
  let numRes;

  if (type === 'ceil') {
    numRes = Math.ceil(numTem * 10 ** decimal) / 100 ** decimal;
  } else if (type === 'floor') {
    numRes = Math.floor(numTem * 10 ** decimal) / 100 ** decimal;
  } else {
    numRes = Math.round(numTem * 10 ** decimal) / 100 ** decimal;
  }

  if (complement && decimal !== 0) {
    const numArr = numRes.toString().split('.');
    numArr[1] = numArr[1] ? numArr[1].padEnd(decimal, '0') : ''.padEnd(decimal, '0');
    return numArr.join('.');
  }

  return numRes.toString();
};
export function filterChildren(arr: any) {
  if (arr.length && arr.length <= 0) return [];
  return arr.map((item: any) => {
    const newItem = item;
    if (item.children && item.children.length <= 0) {
      delete newItem.children;
    } else {
      newItem.children = filterChildren(item.children);
    }
    return newItem;
  });
}

export function handleTreeData(treeData: any[], searchValue: string, parentIds: any[] = []) {
  if (!treeData || treeData.length === 0) {
    return { array: [], parentIds };
  }
  const array: any = [];
  for (let i = 0; i < treeData.length; i += 1) {
    if (
      handleTreeData(treeData[i].children, searchValue, parentIds).array.length > 0 ||
      treeData[i].name.includes(searchValue)
    ) {
      parentIds.push(treeData[i].value);
      array.push({
        ...treeData[i],
        children: handleTreeData(treeData[i].children, searchValue, parentIds).array,
      });
    }
  }
  return { array, parentIds };
}

export function resourceTreeData(
  treeData: any[],
  searchValue: string,
  searchCode: string,
  parentIds: any[] = [],
) {
  if (!treeData || treeData.length === 0) {
    return { array: [], parentIds };
  }

  const array: any = [];
  for (let i = 0; i < treeData.length; i += 1) {
    if (
      resourceTreeData(treeData[i].children, searchValue, searchCode, parentIds).array.length > 0 ||
      ((treeData[i].name.includes(searchValue) || !searchValue) &&
        (treeData[i].data?.code?.includes(searchCode) || !searchCode))
    ) {
      parentIds.push(treeData[i].id);
      array.push({
        ...treeData[i],
        children: resourceTreeData(treeData[i].children, searchValue, searchCode, parentIds).array,
      });
    }
  }
  return { array, parentIds };
}

export function searchTreeData(treeData: any[], searchValue: string): any {
  const newTreeData: any[] = [];
  for (let i = 0; i < treeData.length; i += 1) {
    if (treeData[i].name.includes(searchValue)) {
      newTreeData.push(treeData[i]);
    } else if (treeData[i].children && treeData[i].children.length > 0) {
      const children = searchTreeData(treeData[i].children, searchValue);
      if (children.length > 0) {
        newTreeData.push(treeData[i]);
      }
    }
  }
  return newTreeData;
  // return treeData.filter((item: any) => {
  //   const newItem = item;
  //   if (item.name.includes(searchValue)) {
  //     return true;
  //   }
  //   if (newItem.children && newItem.children.length > 0) {
  //     const children = searchTreeData(newItem.children, searchValue);
  //     if (children.length > 0) {
  //       return true;
  //     }
  //   }
  //   return false;
  // });
}
// 展开收起，获取节点
const getNode = (children: any, initial: any) => {
  const data: any = initial;
  children?.forEach((el: any) => {
    if (el.children && el.children.length !== 0) {
      data.push(el.id);
      if (el.children) getNode(el.children, data);
    }
  });
  return data;
};

export function getNodeShow(children: any | []) {
  const nodeData: any[] = getNode(children, []);
  return nodeData;
}

// 页面按钮权限
export function isPermission(key: string) {
  let is: boolean = false;
  if (isDev) {
    is = true;
  } else {
    const permission = getLocalStorage('permission') || [];
    if (permission?.indexOf(key) !== -1) {
      is = true;
    }
  }
  return is;
}

// 根据子节点id获取节点跟父节点id
export function getFatherNode(arr: any, resourceId: string) {
  let hasFound = false; // 表示是否有找到id值
  let result = null;
  // eslint-disable-next-line no-var
  const fn = function (data: any[]) {
    if (Array.isArray(data) && !hasFound) {
      // 判断是否是数组并且没有的情况下，
      data.forEach((item) => {
        if (item.id === resourceId) {
          result = item.path.split('@');
          hasFound = true;
        } else if (item.children) {
          fn(item.children);
        }
      });
    }
  };
  fn(arr);
  return result;
}

export function isEmpty(a: any) {
  if (a === '') return true; // 检验空字符串
  if (a === 'null') return true; // 检验字符串类型的null
  if (a === 'undefined') return true; // 检验字符串类型的 undefined
  if (!a && a !== 0 && a !== '') return true; // 检验 undefined 和 null
  // eslint-disable-next-line no-prototype-builtins
  if (JSON.stringify(a) === '[]') return true; // 检验空数组
  if (JSON.stringify(a) === '{}') return true; // 检验空对象
  return false;
}

export function getBirth(idCard: string) {
  let birthday = '';
  if (idCard != null && idCard !== '') {
    if (idCard.length === 15) {
      birthday = `19${idCard.slice(6, 12)}`;
    } else if (idCard.length === 18) {
      birthday = idCard.slice(6, 14);
    }
    birthday = birthday.replace(/(.{4})(.{2})/, '$1-$2-');
    // 通过正则表达式来指定输出格式为:1990-01-01
  }
  return birthday;
}

export function getSex(idCard: string) {
  let sexStr = '';
  if (parseInt(idCard.slice(-2, -1), 10) % 2 === 1) {
    sexStr = 'male';
  } else {
    sexStr = 'female';
  }
  return sexStr;
}

/**
 * 时间格式 YYYY-MM-DD
 * @param date moment 格式
 * @param format 格式
 * @returns string
 */
export function formatToDate(date: moment.MomentInput = undefined, format = DATE_FORMAT): string {
  return moment(date).format(format);
}

const getStandardDateBeforeWeek = () => {
  const date = new Date();
  date.setDate(date.getDate() - 6);
  const year = date.getFullYear();
  let month: any = date.getMonth() + 1;
  let day: any = date.getDate();
  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }
  return `${year}-${month}-${day}`;
};

export function getTimeFrame(date: string) {
  const time: any = [];
  const str: any = date.replace(/\s+/g, '');
  const day: any = new Date();
  if (str === '今日') {
    time[0] = `${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`;
    time[1] = `${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`;
  } else if (str === '最近一周') {
    time[0] = getStandardDateBeforeWeek(); // 获取7天前的日期
    time[1] = `${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`;
  } else if (str === '最近一月') {
    const now = new Date();
    const year = now.getFullYear();
    const month: any = now.getMonth() + 1;
    const days = day.getDate();

    time[1] = `${year}-${month}-${days}`;
    let lsTime: string = '';
    const nowMonthDay = new Date(year, month, 0).getDate();
    if (month - 1 <= 0) {
      // 如果是1月，年数往前推一年<br>
      lsTime = `${year - 1}-${12}-${days}`;
    } else {
      const lastMonthDay = new Date(year, parseInt(month, 10) - 1, 0).getDate() + 1;
      if (lastMonthDay < days) {
        // 1个月前所在月的总天数小于现在的天日期
        if (days < nowMonthDay) {
          // 当前天日期小于当前月总天数
          lsTime = `${year}-${month - 1}-${lastMonthDay - (nowMonthDay - days)}`;
        } else {
          lsTime = `${year}-${month - 1}-${lastMonthDay}`;
        }
      } else {
        lsTime = `${year}-${month - 1}-${days}`;
      }
    }
    time[0] = `${lsTime}`;
  } else if (str === '最近半年') {
    const cc = day.getTime();
    const halfYear = (365 / 2) * 24 * 3600 * 1000;
    const pastResult = cc - halfYear;
    const pastDate = new Date(pastResult);
    time[0] = `${pastDate.getFullYear()}-${pastDate.getMonth() + 2}`;
    time[1] = `${new Date()}`;
  } else if (str === '最近一年') {
    const cc = day.getTime();
    const halfYear = 365 * 24 * 3600 * 1000;
    const pastResult = cc - halfYear;
    const pastDate = new Date(pastResult);
    time[0] = `${pastDate.getFullYear()}-${pastDate.getMonth() + 2}`;
    time[1] = `${new Date()}`;
  }
  return [formatToDate(time[0]), formatToDate(time[1])];
}

const phoneReg =
  /^(0|86|17951)?(13[0-9]|15[012356789]|16[2567]|17[0235678]|18[0-9]|19[189]|14[1456789])[0-9]{8}$/;

export const idCardReg =
  /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
/**
 * 校验手机号码是否符合
 * @param mobile 手机号码
 * @returns boolean
 */
export const validIsMobile = (mobile: string) => !!phoneReg.test(mobile);
// 肿瘤分期
export const tumorStagePrefix: any = ['c', 'p', 'r', 'y'];
export const tumorStageT: any = [
  'T0',
  'Tis',
  'Tis(DCIS)',
  'Tis(LCIS)',
  "Tis(Paget's)",
  'T1',
  'T1mi',
  'T1a',
  'T1b',
  'T1c',
  'T2',
  'T3',
  'T4',
  'T4a',
  'T4b',
  'T4c',
  'T4d',
  'Tx',
  '不适用',
];
export const tumorStageN: any = [
  'Nx',
  'N0',
  'N1',
  'N1a',
  'N1b',
  'N1c',
  'N2',
  'N2a',
  'N2b',
  'N3',
  'N+',
];
export const tumorStageM: any = ['Mx', 'M0', 'M1', 'M1a', 'M1b', 'M1c'];
// 肿瘤分期显示名称
export const transformTumorStage = (rootTumorStageStr = '') => {
  let tumorStageName = '';
  if (rootTumorStageStr) {
    const tumorStageStr = rootTumorStageStr.substring(1, rootTumorStageStr.length - 1);
    const tumorStage = tumorStageStr.split(',');
    if (tumorStage.length === 4) {
      tumorStage[2] = tumorStage[2].substring(0, tumorStage[2].length - 1);
      tumorStage[3] = tumorStage[3].substring(1, tumorStage[3].length);
      tumorStageName =
        tumorStagePrefix[tumorStage[3]] +
        tumorStageT[tumorStage[0]] +
        tumorStageN[tumorStage[1]] +
        tumorStageM[tumorStage[2]];
    } else {
      tumorStageName =
        tumorStageT[tumorStage[0]] + tumorStageN[tumorStage[1]] + tumorStageM[tumorStage[2]];
    }
  }
  return tumorStageName;
};

export function parseTime(time: any, cFormat?: string) {
  if (arguments.length === 0 || !time) {
    return null;
  }

  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
  let date;

  let tranTime = time;

  if (typeof time === 'object') {
    date = time;
  } else {
    if (typeof time === 'string') {
      if (/^[0-9]+$/.test(time)) {
        // support "1548221490638"
        tranTime = time;
      } else {
        // eslint-disable-next-line prefer-regex-literals
        tranTime = time.replace(new RegExp(/-/gm), '/');
      }
    }

    if (typeof tranTime === 'number' && tranTime.toString().length === 10) {
      tranTime = time * 1000;
    }
    date = new Date(tranTime);
  }
  const formatObj: any = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const timeStr = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }
    return value.toString().padStart(2, '0');
  });
  return timeStr;
}

export function roleHandleTreeData(treeData: any[], searchValue: string, parentIds: any[] = []) {
  if (!treeData || treeData.length === 0) {
    return { array: [], parentIds };
  }
  const array: any = [];
  for (let i = 0; i < treeData.length; i += 1) {
    if (
      roleHandleTreeData(treeData[i].children, searchValue, parentIds).array.length > 0 ||
      treeData[i].title.includes(searchValue)
    ) {
      parentIds.push(treeData[i].value);
      array.push({
        ...treeData[i],
        children: roleHandleTreeData(treeData[i].children, searchValue, parentIds).array,
      });
    }
  }
  return { array, parentIds };
}

/**
 * @description: 格式化时间 YYYY-MM-DD
 * @author: likaifeng
 * @param {any} date
 * @return {*}
 * @Date: 2023-11-30 10:19:17
 */
export function formatDate(date: any) {
  const d = new Date(date);
  const y = d.getFullYear(); // 年份
  const m = (d.getMonth() + 1).toString().padStart(2, '0'); // 月份
  const r = d.getDate().toString().padStart(2, '0'); // 日子
  // const hh = d.getHours().toString().padStart(2, '0') // 小时
  // const mm = d.getMinutes().toString().padStart(2, '0') // 分钟
  // const ss = d.getSeconds().toString().padStart(2, '0') // 秒
  return `${y}-${m}-${r}`;
}

export function numToChinese(num: number) {
  // 只支持100以内
  const numMap = [
    '零',
    '一',
    '二',
    '三',
    '四',
    '五',
    '六',
    '七',
    '八',
    '九',
    '十',
  ];

  const unitMap = ['', '十', '百', '千', '万', '十', '百', '千', '亿'];

  let result = '';
  const numStr = String(num);
  const len = numStr.length;
  for (let i = 0; i < len; i += 1) {
    // @ts-ignore
    let n = numMap[numStr[i]];
    const unit = unitMap[len - i - 1];
    if (len > 1) {
      if (unit === '' && n === numMap[0]) {
        n = '';
      }
    }
    // @ts-ignore
    result += n + unit;
  }
  return result;
}

export function base64ToFile(base64: any, fileName: string) {
  const arr = base64.split(',');
  const mime = 'image/png';
  const bstr = atob(arr[1]);
  let n: number = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-cond-assign,no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], fileName, { type: mime });
}
// 生成随机密码
export const randomWord = (randomFlag: boolean, min: number, max: number) => {
  let str = '';
  let range = min;
  const arr = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    '-',
    '.',
    '~',
    '!',
    '@',
    '#',
    '$',
    '%',
    '^',
    '&',
    '*',
    '(',
    ')',
    '_',
    ':',
    '<',
    '>',
    '?',
  ];
  if (randomFlag) {
    range = Math.round(Math.random() * (max - min)) + min;
  }
  for (let i = 0; i < range; i += 1) {
    const pos = Math.round(Math.random() * (arr.length - 1));
    str += arr[pos];
  }
  return str;
};

export const copyText = (text: string) => {
  const createInput = document.createElement('input');
  createInput.value = text;
  document.body.appendChild(createInput);
  createInput.select();
  document.execCommand('Copy');
  createInput.remove();
  message.success('已复制');
};

export default {
  getUuid,
  randomLenNum,
  getBase64,
  transformTumorStage,
  getUserInfoFromIdCard,
};
