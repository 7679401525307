/*
 * @Description: 讲稿演示-主要内容-基因检测
 * @Author: likaifeng
 * @Date: 2023-11-30 17:30:28
 * @LastEditTime: 2023-12-01 09:46:41
 * @LastEditors: likaifeng
 */
import { speechFilterMakeDataAtom } from '@/store/lecture';
import React from 'react';
import { useRecoilValue } from 'recoil';
import SpeechCard from '../SpeechCard';
import SpeechField from '../SpeechField';
import SpeechStep from '../SpeechStep';
import SpeechStepCard from '../SpeechStepCard';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-基因检测
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:26:32
 */
const SpeechGene: React.FC = () => {
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);

  return speechFilterMakeData?.geneticTesting?.length ? (
    <SpeechCard titleId="geneticTesting" title="基因检测">
      <div className={styles['speech-discharge']}>
        {speechFilterMakeData?.geneticTesting?.map((item: any) => (
          <SpeechStep
            key={item.id}
            id={`geneticTesting_${item.id}`}
            time={`检查时间：${item.recordTime || '-'}`}
          >
            <SpeechStepCard
              padding="16px 16px 4px 16px"
              disabled={item.imagePath && item.imagePath.length}
            >
              <SpeechField
                title="报告图片"
                border={false}
                type="IMAGE"
                imagesSource={item.imagePath}
              />
            </SpeechStepCard>
            <SpeechStepCard>
              <SpeechField title="结果描述" border="false" description={item.description} />
            </SpeechStepCard>
          </SpeechStep>
        ))}
      </div>
    </SpeechCard>
  ) : null;
};

export default SpeechGene;
