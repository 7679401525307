/*
 * @Description: 讲稿演示-主要内容
 * @Author: likaifeng
 * @Date: 2023-11-30 16:10:48
 * @LastEditTime: 2023-11-30 19:18:42
 * @LastEditors: likaifeng
 */
import { FloatButton } from '@sinohealth/butterfly-ui-components';
import React from 'react';
import SpeechAdmitted from '../SpeechAdmitted';
import SpeechContrast from '../SpeechContrast';
import SpeechDischarge from '../SpeechDischarge';
import SpeechFooter from '../SpeechFooter';
import SpeechGene from '../SpeechGene';
import SpeechInspect from '../SpeechInspect';
import SpeechMedica from '../SpeechMedica';
import SpeechPathological from '../SpeechPathological';
import SpeechPatient from '../SpeechPatient';
import SpeechSummary from '../SpeechSummary';
import SpeechSurgery from '../SpeechSurgery';
import SpeechTendency from '../SpeechTendency';
import SpeechVideoReport from '../SpeechVideoReport';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:27:21
 */
const SpeechMain: React.FC = () => {
  return (
    <div id="speechMain" className={styles['speech-main']}>
      <SpeechPatient />
      <SpeechSummary />
      <SpeechAdmitted />
      <SpeechDischarge />
      <SpeechVideoReport />
      <SpeechSurgery />
      <SpeechPathological />
      <SpeechInspect />
      <SpeechGene />
      <SpeechMedica />
      <SpeechContrast />
      <SpeechTendency />
      <SpeechFooter />
      <FloatButton.BackTop target={() => document.getElementById('speechMain') as HTMLElement} />
    </div>
  );
};

export default SpeechMain;
