/*
 * @Description: 讲稿演示-MDT议题
 * @Author: likaifeng
 * @Date: 2023-11-30 19:23:34
 * @LastEditTime: 2023-12-04 14:27:16
 * @LastEditors: likaifeng
 */
import { speechFilterMakeDataAtom, speechIssueFlagAtom } from '@/store/lecture';
import classNames from 'classnames';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import styles from './index.less';

/**
 * @description: 讲稿演示-MDT议题
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:27:11
 */
const SpeechIssue: React.FC = () => {
  const [speechIssueFlag, setSpeechIssueFlag] = useRecoilState(speechIssueFlagAtom);
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);

  return speechIssueFlag ? (
    <div className={styles['speech-issue']}>
      <div className={styles['issue-header']}>
        <div className={styles.issue}>
          <i className="iconfont icon-fuzhiwenjian" style={{ marginRight: 5 }} />
          MDT议题
        </div>
        <i
          className={classNames('iconfont icon-choutizhankai', styles['issue-close'])}
          onClick={() => setSpeechIssueFlag(false)}
        />
      </div>
      <div className={styles['issue-container']}>
        {speechFilterMakeData?.mdtIssues?.map((el: any, index: number) => (
          <div key={el.id} className={styles['issue-item']}>
            <div className={styles['item-header']}>
              <div className={styles['header-icon']}>议题{index + 1}</div>
            </div>
            <div className={styles.container}>{el.issue}</div>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className={styles['issue-open']} onClick={() => setSpeechIssueFlag(!speechIssueFlag)}>
      <span>MDT 议题</span>
      <i className="iconfont icon-choutishouqi" style={{ marginTop: 4 }} />
    </div>
  );
};

export default SpeechIssue;
