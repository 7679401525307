/**
 * 权限编码表
 * * */
const codes: any = {
  commonLayout: {
    team: 'isShowTeam', // 是否显示当前机构
  },
  // 患者列表
  patientList: {
    add: 'patientListAdd', // 添加患者
    delete: 'patientListDelete', // 删除患者
    ppt: 'patientListPPT', // PPT报告列
    mdt: 'patientListMdt', // MDT列
    shareBtn: 'patientListShare', // 分享患者按钮
    lectureBtn: 'patientListLecture', // 制作讲稿按钮
  },
  // 患者详情
  patientDetail: {
    edit: 'patientDetailEdit', // 编辑患者权限
  },
  // MDT团队列表
  mdtTeamList: {
    add: 'mdtAdd', // 新增团队
    delete: 'mdtDelete', // 删除团队
    detail: 'mdtTeamDetails', // 查询团队详情
  },
  // MDT团队详情
  mdtTeamDetail: {
    mdtUpdate: 'mdtUpdate', // 基本信息
    editMember: 'editMember', // 编辑成员
    changeAdmin: 'mtdMemberUpdate', // 更换管理员
    addMember: 'addMember', // 新增成员
    deleteMember: 'deleteMember', // 删除成员
  },
  // 病例库权限
  caseHistoryList: {
    delPtt: 'patientPPTDelete',
    delMdt: 'patientMdtReportDelete',
    patiendDetail: 'casePatientDetail',
    caseMaking: 'caseMaking',
    caseConsultationDetail: 'caseConsultationDetail',
    shareOrCancel: 'shareOrCancel',
  },
  doctorList: {
    add: 'doctorListAdd', // 添加医生
  },
  doctorDetail: {
    edit: 'DoctorMangeDetailEdit', // 编辑医生
    // 医生详情-患者列表
    patient: {
      add: 'DoctorPatientListAdd', // 添加患者
      delete: 'DoctorPatientListDelete', // 删除患者
      ppt: 'DoctorPatientListPPT', // PPT报告列
      mdt: 'DoctorPatientListMdt', // MDT列
      shareBtn: 'DoctorPatientListShare', // 分享患者按钮
      lectureBtn: 'DoctorPatientListLecture', // 制作讲稿按钮
    },
  },
  doctorInfoList: {
    add: 'DoctorInfoAdd', // 添加医生信息
    delete: 'DoctorInfoDelete', // 删除医生信息
  },
  doctorAssistant: {
    add: 'DoctorAssistantListAdd', // 添加医助
    delete: 'DoctorAssistantListDelete', // 删除医助
  },
  // 机构管理权限
  institutionAdminList: {
    isSearchAllAdd: 'mechanismManagement:mechanismAdd:isSearchAllAddNew', // 机构管理-添加机构-是否查询所有
  },
};
export default codes;
