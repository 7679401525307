/*
 * @Description: 讲稿预览文件处理
 * @Author: likaifeng
 * @Date: 2023-12-03 18:22:40
 * @LastEditTime: 2023-12-04 11:59:23
 * @LastEditors: likaifeng
 */
import useFetchImage from '@/pages/speechLecture/hook/fileDeal';
import { Image } from '@sinohealth/butterfly-ui-components';

import React, { useEffect, useState } from 'react';
import SpeechVideoModel from '../SpeechVideoModel';

/**
 * @description: 讲稿预览文件处理
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-03 18:27:18
 */
const SpeechFile: React.FC<any> = (props) => {
  const { url, type, className, onClick, ...other } = props;

  const [videoUrl, setVideoUrl] = useState('');

  const { intactFileUlr, setOriginUrl } = useFetchImage(url);

  /**
   * @description: 预览视频
   * @author: likaifeng
   * @param {string} url
   * @return {*}
   * @Date: 2023-12-03 17:54:44
   */
  const handlePreviewVideo = (u: string) => {
    setVideoUrl(u);
  };

  useEffect(() => {
    setOriginUrl(url);
  }, [url]);

  if (!intactFileUlr) return null;

  return type === 'image' ? (
    <div className={className}>
      <Image src={intactFileUlr} onClick={onClick} {...other} />
    </div>
  ) : (
    <>
      <video
        muted
        loop
        autoPlay
        className={className}
        {...other}
        onClick={() => handlePreviewVideo(intactFileUlr)}
      >
        <source src={intactFileUlr} />
      </video>
      {/* 预览video */}
      <SpeechVideoModel url={videoUrl} urlType="VIDEO" title="查看报告视频" />
    </>
  );
};

export default SpeechFile;
