/*
 * @Description: 协议
 * @Author: likaifeng
 * @Date: 2023-12-03 20:46:36
 * @LastEditTime: 2023-12-03 20:54:27
 * @LastEditors: likaifeng
 */
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { privacyPolicy, userServiceProtocol } from './config';
import styles from './index.less';

/**
 * @description: 协议
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-03 20:54:52
 */
const userProtocol: React.FC = () => {
  const [params] = useSearchParams();
  const id = params.getAll('id')[0];

  return (
    <div
      className={styles['user-protocol']}
      dangerouslySetInnerHTML={{
        __html: id === '1' ? userServiceProtocol : privacyPolicy,
      }}
    />
  );
};

export default userProtocol;
