/*
 * @Description: 讲稿演示-主要内容-趋势图
 * @Author: likaifeng
 * @Date: 2023-11-30 18:58:16
 * @LastEditTime: 2023-12-03 20:32:29
 * @LastEditors: likaifeng
 */
import { speechFilterMakeDataAtom } from '@/store/lecture';
import React from 'react';
import { useRecoilValue } from 'recoil';
import SpeechCard from '../SpeechCard';
import SpeechField from '../SpeechField';
import SpeechStep from '../SpeechStep';
import SpeechStepCard from '../SpeechStepCard';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-趋势图
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:29:07
 */
const SpeechTendency: React.FC = () => {
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);

  return speechFilterMakeData?.correlationCharts?.length ? (
    <SpeechCard titleId="correlationCharts" title="趋势图">
      <div className={styles['speech-admitted']}>
        {speechFilterMakeData?.correlationCharts?.map((item: any) => (
          <SpeechStep key={item.id} id={`correlationCharts_${item.id}`} time={item.name}>
            <SpeechStepCard
              padding="16px 16px 4px 16px"
              disabled={item.thumbnail || item.instruction}
            >
              <SpeechField
                compareTitle="报告图片"
                border={false}
                style={{ marginBottom: item.instruction ? 4 : 0 }}
                type="IMAGE"
                imagesSource={item.thumbnail}
              />
              <SpeechField title="图片说明" description={item.instruction} />
            </SpeechStepCard>
          </SpeechStep>
        ))}
      </div>
    </SpeechCard>
  ) : null;
};

export default SpeechTendency;
