/*
 * @Description: 讲稿演示-主要内容-卡片
 * @Author: likaifeng
 * @Date: 2023-11-30 16:13:11
 * @LastEditTime: 2023-12-05 11:23:14
 * @LastEditors: likaifeng
 */
import { speechMainDetailAtom, speechMainDetailTabAtom } from '@/store/lecture';
import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-卡片
 * @author: likaifeng
 * @param {*} props
 * @return {*}
 * @Date: 2023-12-04 14:24:48
 */
const SpeechCard: React.FC<any> = (props) => {
  const setSpeechMainDetail = useSetRecoilState(speechMainDetailAtom);
  const setSpeechMainDetailTab = useSetRecoilState(speechMainDetailTabAtom);

  return (
    <div id={props?.id} className={styles['speech-card']} style={props.styles}>
      <div id={props?.titleId} className={styles['card-title']}>
        <span className={styles.title}>{props.title}</span>
        {/* <i className={`iconfont icon-quxiao ${styles.close}`} /> */}
        {props?.close ? (
          <CloseOutlined
            onClick={() => {
              setSpeechMainDetail(null);
              setSpeechMainDetailTab(null);
            }}
          />
        ) : null}
      </div>
      {props.children}
    </div>
  );
};

export default SpeechCard;
