/*
 * @Description: 讲稿演示-左侧大纲
 * @Author: likaifeng
 * @Date: 2023-11-30 16:02:22
 * @LastEditTime: 2023-12-03 20:21:41
 * @LastEditors: likaifeng
 */
import {
  speechMainDetailAtom,
  speechMainDetailTabAtom,
  speechSideMenuAtom,
  speechSideTabAtom,
} from '@/store/lecture';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import styles from './index.less';

const SpeechSide: React.FC = () => {
  const speechSideMenu = useRecoilValue(speechSideMenuAtom);
  const [speechSideTab, setSpeechSideTab] = useRecoilState(speechSideTabAtom);
  const setSpeechMainDetailTab = useSetRecoilState(speechMainDetailTabAtom);
  const setSpeechMainDetail = useSetRecoilState(speechMainDetailAtom);

  /**
   * @description: 切换左侧讲稿大纲
   * @author: likaifeng
   * @param {string} key
   * @return {*}
   * @Date: 2023-11-30 20:24:45
   */
  const handleChangeSideMenu = (key: string) => {
    setSpeechSideTab(key);
    setSpeechMainDetailTab(null);
    setSpeechMainDetail(null);
    document.querySelector(`#${key}`)?.scrollIntoView();
  };

  return (
    <div className={styles['speech-side']}>
      <div className={styles['side-header']}>
        <div className={styles.side}>
          <i className="iconfont icon-fuzhiwenjian" style={{ marginRight: 5 }} />
          讲稿大纲
        </div>
      </div>
      <ul className={styles['side-container']}>
        {speechSideMenu?.map((menu: any, index: number) => (
          <li key={menu.label} className={styles['side-step']}>
            <div
              id={`menu_${menu.label}`}
              className={`${styles['step-title']} ${
                speechSideTab.indexOf(menu.label) !== -1 ? styles['step-title-active'] : ''
              }`}
              onClick={() => handleChangeSideMenu(menu.label)}
            >
              {index + 1}. {menu.name}
              {menu.children ? (
                <span className={styles['title-count']}>{menu.children.length}</span>
              ) : null}
            </div>
            {menu.children ? (
              <div className={styles.container}>
                {menu.children.map((child: any) => (
                  <div
                    key={child.label}
                    className={`${styles['step-item']} ${
                      speechSideTab === child.label ? styles['step-item-active'] : ''
                    }`}
                    onClick={() => handleChangeSideMenu(child.label)}
                  >
                    <span
                      className={`${styles.step} ${
                        speechSideTab === child.label ? styles['step-active'] : ''
                      }`}
                    >
                      {child.name}
                    </span>
                    {child.type ? <span className={styles['step-ct']}>{child.type}</span> : null}
                  </div>
                ))}
              </div>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SpeechSide;
