/*
 * @Description: 讲稿演示-主要内容-影像报告
 * @Author: likaifeng
 * @Date: 2023-11-30 17:13:52
 * @LastEditTime: 2023-12-13 15:16:40
 * @LastEditors: likaifeng
 */
import { speechFilterMakeDataAtom } from '@/store/lecture';
import React from 'react';
import { useRecoilValue } from 'recoil';
import SpeechCard from '../SpeechCard';
import SpeechField from '../SpeechField';
import SpeechStep from '../SpeechStep';
import SpeechStepCard from '../SpeechStepCard';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-影像报告
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:29:28
 */
const SpeechVideoReport: React.FC = () => {
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);

  return speechFilterMakeData?.imageReport?.length ? (
    <SpeechCard titleId="imageReport" title="影像报告">
      <div className={styles['speech-discharge']}>
        {speechFilterMakeData?.imageReport?.map((item: any) => (
          <SpeechStep
            key={item.id}
            id={`imageReport_${item.id}`}
            time={`检查时间：${item.recordTime || '-'}`}
          >
            <SpeechStepCard
              padding="16px 16px 4px 16px"
              disabled={item.reportImage && item.reportImage.length}
            >
              <SpeechField
                title="报告图片/视频"
                border={false}
                type="IMAGE"
                imagesSource={item.reportImage}
              />
            </SpeechStepCard>
            <SpeechStepCard
              disabled={
                item.imagesType ||
                item.parts ||
                item.zhWedcmTask ||
                item.reportDescription ||
                item.reportDiagnose
              }
            >
              <SpeechField border title="影像类型" description={item.imagesType} />
              <SpeechField border title="检查部位" description={item.parts} />
              <SpeechField
                border
                title="DICOM"
                type="BUTTON"
                dicomId={item.id}
                zhWedcmTask={item.zhWedcmTask}
                description={item.zhWedcmTask ? '查看影像' : ''}
              />
              <SpeechField border title="检查所见" description={item.reportDescription} />
              <SpeechField border title="检查结论" description={item.reportDiagnose} />
            </SpeechStepCard>
          </SpeechStep>
        ))}
      </div>
    </SpeechCard>
  ) : null;
};

export default SpeechVideoReport;
