/*
 * @Description: 讲稿演示-主要内容-病理报告
 * @Author: likaifeng
 * @Date: 2023-11-30 17:25:45
 * @LastEditTime: 2023-12-03 20:31:52
 * @LastEditors: likaifeng
 */
import { speechFilterMakeDataAtom } from '@/store/lecture';
import React from 'react';
import { useRecoilValue } from 'recoil';
import SpeechCard from '../SpeechCard';
import SpeechField from '../SpeechField';
import SpeechStep from '../SpeechStep';
import SpeechStepCard from '../SpeechStepCard';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-病理报告
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:27:41
 */
const SpeechPathological: React.FC = () => {
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);

  return speechFilterMakeData?.pathologyReport?.length ? (
    <SpeechCard titleId="pathologyReport" title="病理报告">
      <div className={styles['speech-discharge']}>
        {speechFilterMakeData?.pathologyReport?.map((item: any) => (
          <SpeechStep
            key={item.id}
            id={`pathologyReport_${item.id}`}
            time={`检查时间：${item.recordTime || '-'}`}
          >
            <SpeechStepCard
              padding="16px 16px 4px 16px"
              disabled={item.imagePath && item.imagePath.length}
            >
              <SpeechField
                title="报告图片"
                border={false}
                type="IMAGE"
                imagesSource={item.imagePath}
              />
            </SpeechStepCard>
            <SpeechStepCard
              disabled={
                item.summary || item.tumorStaging || item.immunohistochemistry || item.other
              }
            >
              <SpeechField border title="病理概述" description={item.summary} />
              <SpeechField border title="病理分期" description={item.tumorStaging} />
              <SpeechField border title="免疫组化" description={item.immunohistochemistry} />
              <SpeechField title="其他信息" description={item.other} />
            </SpeechStepCard>
          </SpeechStep>
        ))}
      </div>
    </SpeechCard>
  ) : null;
};

export default SpeechPathological;
