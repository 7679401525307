/* eslint-disable indent */
/*
 * @Description: 讲稿演示-快捷预览
 * @Author: likaifeng
 * @Date: 2023-11-30 19:19:50
 * @LastEditTime: 2023-12-20 16:15:10
 * @LastEditors: likaifeng
 */
import useDict from '@/hooks/dict/useDict';
import {
  DrugRecordCheckbox,
  GeneticTestingCheckbox,
  HospitalDischargeRecordsCheckbox,
  ImageReportCheckbox,
  MainDetailTime,
  MedicalRecordCheckbox,
  PathologyReportCheckbox,
  SurgicalRecordCheckbox,
} from '@/pages/speechLecture/hook/config';
import {
  speechMainDetailAtom,
  speechMainDetailTabAtom,
  speechTabEnumKeyAtom,
} from '@/store/lecture';
import { inspctionType } from '@/utils/constant';
import { Table } from '@sinohealth/butterfly-ui-components';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import SpeechCard from '../SpeechCard';
import SpeechField from '../SpeechField';
import SpeechStepCard from '../SpeechStepCard';
import styles from './index.less';

/**
 * @description: 讲稿演示-快捷预览
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:28:29
 */
const SpeechSubfield: React.FC = () => {
  const speechMainDetail = useRecoilValue(speechMainDetailAtom);
  const speechTabEnumKey = useRecoilValue(speechTabEnumKeyAtom);
  const speechMainDetailTab = useRecoilValue(speechMainDetailTabAtom);
  const tumorMarker = useDict('tumorMarker');

  const [time, setTime] = useState('');

  /**
   * @description: 计算表格的列
   * @author: likaifeng
   * @param {any} data
   * @return {*}
   * @Date: 2023-12-01 10:17:53
   */
  const clacTableColumns = (data: any): any => {
    const arr: any = [];

    if (!data) return arr;

    if (data?.type) {
      arr.push({
        title: '检验项目',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        render(text: string, item: any): JSX.Element {
          return <span>{inspctionType[Number(text)]}</span>;
        },
      });
    }
    if (data?.tumorMarkers) {
      arr.push({
        title: '指标名称',
        dataIndex: 'tumorMarkers',
        key: 'tumorMarkers',
        align: 'center',
        render(text: string, item: any): JSX.Element {
          return <span>{item?.type === '0' ? tumorMarker?.[text] : text}</span>;
        },
      });
    }
    if (data?.unit) {
      arr.push({
        title: '单位',
        dataIndex: 'unit',
        key: 'unit',
        align: 'center',
      });
    }
    if (data?.resultValueItem) {
      arr.push({
        title: '结果',
        dataIndex: 'resultValueItem',
        key: 'resultValueItem',
        align: 'center',
      });
    }
    if (data?.referenceItem) {
      arr.push({
        title: '参考区间',
        dataIndex: 'referenceItem',
        key: 'referenceItem',
        align: 'center',
      });
    }

    return arr;
  };

  const calcShowImageCard = () => {
    return (
      (speechMainDetailTab === 'medicalRecord' ||
        speechMainDetailTab === 'hospitalDischargeRecords' ||
        speechMainDetailTab === 'pathologyReport' ||
        speechMainDetailTab === 'drugRecord' ||
        speechMainDetailTab === 'imageReport' ||
        speechMainDetailTab === 'geneticTesting' ||
        speechMainDetailTab === 'surgicalRecord' ||
        speechMainDetailTab === 'inspectionReport') &&
      (!!speechMainDetail?.imagePath?.length || !!speechMainDetail?.reportImage?.length)
    );
  };

  useEffect(() => {
    if (speechMainDetailTab === 'hospitalDischargeRecords') {
      setTime(speechMainDetail?.dischargeTime);
    } else {
      setTime(speechMainDetail?.recordTime);
    }
  }, [speechMainDetailTab]);

  return speechMainDetail ? (
    <SpeechCard title="详细信息" style={{ marginBottom: 0, marginLeft: 10 }} close>
      <div className={styles['speech-subfield']}>
        {/* 时间处理 */}
        <SpeechStepCard margin="0 0 16px 0" background="#E6F1FC">
          <SpeechField
            color
            title={speechTabEnumKey[speechMainDetailTab || '']}
            border={false}
            description={`${MainDetailTime[speechMainDetailTab || '']}：${time}`}
          />
        </SpeechStepCard>
        {/* 图片处理 */}
        <SpeechStepCard
          disabled={calcShowImageCard()}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 4px 16px"
        >
          {/* 入院记录 出院记录 影像报告 */}
          <SpeechField
            title="报告图片"
            color
            border={false}
            type="IMAGE"
            imagesSource={speechMainDetail.imagePath}
          />
          {/* 手术信息 影像报告 */}
          <SpeechField
            title={speechMainDetailTab === 'imageReport' ? '报告图片/视频' : '报告图片'}
            color
            border={false}
            type="IMAGE"
            imagesSource={speechMainDetail.reportImage}
          />
        </SpeechStepCard>
        {/* 入院记录 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'medicalRecord'}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          {MedicalRecordCheckbox?.map((item: any, index: number) =>
            item.key !== 'recordTime' && item.key !== 'imagePath' ? (
              <SpeechField
                key={item.key}
                color
                title={item.value}
                border={index !== MedicalRecordCheckbox.length - 1}
                description={speechMainDetail[item.key]}
              />
            ) : null,
          )}
        </SpeechStepCard>
        {/* 出院记录 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'hospitalDischargeRecords'}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          {HospitalDischargeRecordsCheckbox?.map((item: any, index: number) =>
            item.key !== 'dischargeTime' && item.key !== 'imagePath' ? (
              <SpeechField
                key={item.key}
                color
                title={item.value}
                border={index !== HospitalDischargeRecordsCheckbox.length - 1}
                description={speechMainDetail[item.key]}
              />
            ) : null,
          )}
        </SpeechStepCard>
        {/* 影像报告 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'imageReport'}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          {ImageReportCheckbox?.map((item: any, index: number) => {
            if (
              item.key !== 'recordTime' &&
              item.key !== 'reportImage' &&
              item.key !== 'zhWedcmTask'
            ) {
              return (
                <SpeechField
                  key={item.key}
                  color
                  title={item.value}
                  border={index !== ImageReportCheckbox.length - 1}
                  description={speechMainDetail[item.key]}
                />
              );
            }
            if (item.key === 'zhWedcmTask') {
              return (
                <SpeechField
                  key={item.key}
                  color
                  type="BUTTON"
                  dicomId={speechMainDetail.id}
                  zhWedcmTask={speechMainDetail.zhWedcmTask}
                  title={item.value}
                  border={index !== ImageReportCheckbox.length - 1}
                  description={speechMainDetail.zhWedcmTask ? '查看影像' : ''}
                />
              );
            }
            return null;
          })}
        </SpeechStepCard>
        {/* 手术信息 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'surgicalRecord'}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          {SurgicalRecordCheckbox?.map((item: any, index: number) =>
            item.key !== 'recordTime' && item.key !== 'reportImage' ? (
              <SpeechField
                key={item.key}
                color
                title={item.value}
                border={index !== SurgicalRecordCheckbox.length - 1}
                description={speechMainDetail[item.key]}
              />
            ) : null,
          )}
        </SpeechStepCard>
        {/* 病理报告 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'pathologyReport'}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          {PathologyReportCheckbox?.map((item: any, index: number) =>
            item.key !== 'recordTime' && item.key !== 'imagePath' ? (
              <SpeechField
                key={item.key}
                color
                title={item.value}
                border={index !== PathologyReportCheckbox.length - 1}
                description={speechMainDetail[item.key]}
              />
            ) : null,
          )}
        </SpeechStepCard>
        {/* 检验报告 */}
        <SpeechStepCard
          disabled={
            speechMainDetailTab === 'inspectionReport' &&
            speechMainDetail?.zhInspectionItems?.length
          }
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          <SpeechField
            disabled={!speechMainDetail.zhInspectionItems}
            type="OTHER"
            border
            color
            title="检验项目"
          >
            {speechMainDetail?.zhInspectionItems?.length ? (
              <Table
                rowKey="id"
                dataSource={speechMainDetail?.zhInspectionItems}
                columns={clacTableColumns(speechMainDetail?.zhInspectionItems?.[0])}
                pagination={false}
              />
            ) : null}
          </SpeechField>
        </SpeechStepCard>
        <SpeechStepCard
          disabled={speechMainDetailTab === 'inspectionReport' && speechMainDetail.description}
          background="#E6F1FC"
          margin="0 0 16px 0"
        >
          <SpeechField
            title="描述"
            color
            border={false}
            description={speechMainDetail.description}
          />
        </SpeechStepCard>
        {/* 用药记录 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'drugRecord'}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          {DrugRecordCheckbox?.map((item: any, index: number) =>
            item.key !== 'recordTime' && item.key !== 'imagePath' ? (
              <SpeechField
                key={item.key}
                color
                title={item.value}
                border={index !== DrugRecordCheckbox.length - 1}
                description={speechMainDetail[item.key]}
              />
            ) : null,
          )}
        </SpeechStepCard>
        {/* 基因检测 */}
        <SpeechStepCard
          disabled={speechMainDetailTab === 'geneticTesting'}
          background="#E6F1FC"
          margin="0 0 16px 0"
          padding="16px 16px 16px 16px"
        >
          {GeneticTestingCheckbox?.map((item: any, index: number) =>
            item.key !== 'recordTime' && item.key !== 'imagePath' ? (
              <SpeechField
                key={item.key}
                color
                title={item.value}
                border={index !== GeneticTestingCheckbox.length - 1}
                description={speechMainDetail[item.key]}
              />
            ) : null,
          )}
        </SpeechStepCard>
      </div>
    </SpeechCard>
  ) : null;
};

export default SpeechSubfield;
