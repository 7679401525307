/*
 * @Description: 讲稿演示-主要内容-对比图片
 * @Author: likaifeng
 * @Date: 2023-11-30 18:52:47
 * @LastEditTime: 2023-12-19 18:05:07
 * @LastEditors: likaifeng
 */
import { speechFilterMakeDataAtom } from '@/store/lecture';
import React from 'react';
import { useRecoilValue } from 'recoil';
import SpeechCard from '../SpeechCard';
import SpeechField from '../SpeechField';
import SpeechStep from '../SpeechStep';
import SpeechStepCard from '../SpeechStepCard';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-对比图片
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:25:08
 */
const SpeechContrast: React.FC = () => {
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);

  return speechFilterMakeData?.compareImage?.length ? (
    <SpeechCard titleId="compareImage" title="对比图片">
      <div className={styles['speech-admitted']}>
        {speechFilterMakeData?.compareImage?.map((item: any) => (
          <SpeechStep key={item.id} id={`compareImage_${item.id}`} time={item.name}>
            <SpeechStepCard
              padding="16px 16px 4px 16px"
              disabled={item.previousImage.length || item.nextImage.length}
            >
              <SpeechField
                compareTitle={item.name}
                border={false}
                style={{ marginBottom: item.instruction ? 4 : 0 }}
                type="COMPARE"
                imagesSource={[...item.nextImage, ...item.previousImage]}
              />
              <SpeechField title="图片说明" description={item.instruction} />
            </SpeechStepCard>
          </SpeechStep>
        ))}
      </div>
    </SpeechCard>
  ) : null;
};

export default SpeechContrast;
