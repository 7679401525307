/*
 * @Description: 讲稿演示-主要内容-用药记录
 * @Author: likaifeng
 * @Date: 2023-11-30 17:32:28
 * @LastEditTime: 2023-11-30 21:26:58
 * @LastEditors: likaifeng
 */
import { speechFilterMakeDataAtom } from '@/store/lecture';
import React from 'react';
import { useRecoilValue } from 'recoil';
import SpeechCard from '../SpeechCard';
import SpeechField from '../SpeechField';
import SpeechStep from '../SpeechStep';
import SpeechStepCard from '../SpeechStepCard';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-用药记录
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:27:31
 */
const SpeechMedica: React.FC = () => {
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);

  return speechFilterMakeData?.drugRecord?.length ? (
    <SpeechCard titleId="drugRecord" title="用药记录">
      <div className={styles['speech-discharge']}>
        {speechFilterMakeData?.drugRecord?.map((item: any) => (
          <SpeechStep
            key={item.id}
            id={`drugRecord_${item.id}`}
            time={`医嘱时间：${item.recordTime || '-'}`}
          >
            <SpeechStepCard
              padding="16px 16px 4px 16px"
              disabled={item.imagePath && item.imagePath.length}
            >
              <SpeechField
                title="报告图片"
                border={false}
                type="IMAGE"
                imagesSource={item.imagePath}
              />
            </SpeechStepCard>
            <SpeechStepCard
              disabled={
                item.useTime || item.drugPlan || item.adverseReactions || item.treatmentEffect
              }
            >
              <SpeechField border title="用药时期" description={item.useTime} />
              <SpeechField border title="用药方案" description={item.drugPlan} />
              <SpeechField border title="不良反应" description={item.adverseReactions} />
              <SpeechField title="治疗效果" description={item.treatmentEffect} />
            </SpeechStepCard>
          </SpeechStep>
        ))}
      </div>
    </SpeechCard>
  ) : null;
};

export default SpeechMedica;
