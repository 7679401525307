/* eslint-disable quote-props */
/*
 * @Description:
 * @Author: likaifeng
 * @Date: 2023-11-21 15:55:07
 * @LastEditTime: 2023-12-04 17:20:33
 * @LastEditors: likaifeng
 */

// 星期
export const weekList = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];

// 24小时时间列表
// 时间间隔
export const twentyFourHalfHours = [
  '8:00-8:30',
  '8:30-9:00',
  '9:00-9:30',
  '9:30-10:00',
  '10:00-10:30',
  '10:30-11:00',
  '11:00-11:30',
  '11:30-12:00',
  '12:00-12:30',
  '12:30-13:00',
  '13:00-13:30',
  '13:30-14:00',
  '14:00-14:30',
  '14:30-15:00',
  '15:00-15:30',
  '15:30-16:00',
  '16:00-16:30',
  '16:30-17:00',
  '17:00-17:30',
  '17:30-18:00',
  '18:00-18:30',
  '18:30-19:00',
  '19:00-19:30',
  '19:30-20:00',
  '20:00-20:30',
  '20:30-21:00',
  '21:00-21:30',
  '21:30-22:00',
  '22:00-22:30',
  '22:30-23:00',
  '23:00-23:30',
  '23:30-24:00',
];

/**
 * @description: 合同模板状态
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 17:21:13
 */
export const verificationStatus: any = {
  '0': '未审核',
  '1': '审核通过',
  '2': '审核不通过',
};

// 24小时
export const hours24 = [
  '8:00',
  '8:30',
  '9:00',
  '9:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
  '24:00',
];

// 会诊状态
export const cstatus = ['创建中', '未开始', '进行中', '已失效', '已完成'];

// 肿瘤分期
export const tumorStagePrefix = ['c', 'p', 'r', 'y'];
export const tumorStageT = [
  'T0',
  'Tis',
  'Tis(DCIS)',
  'Tis(LCIS)',
  "Tis(Paget's)",
  'T1',
  'T1mi',
  'T1a',
  'T1b',
  'T1c',
  'T2',
  'T3',
  'T4',
  'T4a',
  'T4b',
  'T4c',
  'T4d',
  'Tx',
  '不适用',
];
export const tumorStageN = ['Nx', 'N0', 'N1', 'N1a', 'N1b', 'N1c', 'N2', 'N2a', 'N2b', 'N3', 'N+'];
export const tumorStageM = ['Mx', 'M0', 'M1', 'M1a', 'M1b', 'M1c'];

// 性别
export const gender = ['男', '女', '保密'];

// 团队被邀约后处理状态
export const tstatus = ['未处理', '同意', '拒绝'];

// 检验项目
export const inspctionType = ['肿瘤标志物', '其他'];

/** 影像类型 */
export const imageType = [
  { label: 1, name: 'CT' },
  { label: 2, name: 'X光' },
  { label: 3, name: 'US' },
  { label: 4, name: 'MRI' },
  { label: 5, name: 'PET-CT' },
  { label: 6, name: '其他' },
];
