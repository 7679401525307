/*
 * @Description: 讲稿演示-页脚
 * @Author: likaifeng
 * @Date: 2023-11-30 19:00:41
 * @LastEditTime: 2023-11-30 19:01:31
 * @LastEditors: likaifeng
 */
import React from 'react';
import styles from './index.less';

/**
 * @description: 讲稿演示-页脚
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:26:22
 */
const SpeechFooter: React.FC = () => {
  return (
    <div className={styles['speech-footer']}>
      <div className={styles.logo} />
      <div>免责声明</div>
      <div>
        本报告中，所有医疗信息、图文影像信息、交流、咨询信息等皆不作为诊断结果和医疗证据，医生与患者只能作为参考。
        中惠医疗科技有限公司不承担医生、患者在使用本报中告任何信息时可能产生的医疗风险和责任。
      </div>
      <div>Copyright © 2022 广州中惠医疗科技有限公司</div>
    </div>
  );
};

export default SpeechFooter;
