import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import dictAtom from '@/store/dict';
import locationDictData from '@/hooks/dict/locationDictData';

/*
* 获取字典选项数据
* @params: dictName 字典类型key
* @return: [{ label: '', value: '' }]
* */
const useDictOptions = (dictName: string = '') => {
  const [dictOptions, setDictOptions] = useRecoilState<any>(dictAtom);

  useEffect(() => {
    setDictOptions(locationDictData);
  }, []);

  return dictName ? dictOptions[dictName] : dictOptions;
};

export default useDictOptions;
