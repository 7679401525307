import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@sinohealth/butterfly-ui-components';
import useCurrentRoute from '@/hooks/useCurrentRoute';
import menuConfig, { MenuItem } from '@/config/menu';
import styles from './index.less';

const Navbar = () => {
  const navigate = useNavigate();
  const [menuPaths, setMenuPaths] = useState<any>([]);
  const [isShowBackBtn, setIsShowBackBtn] = useState(false);
  const currentRoute = useCurrentRoute();

  useEffect(() => {
    const paths = mapMenu(menuConfig);
    setMenuPaths(paths);
  }, []);

  useEffect(() => {
    if (currentRoute) {
      setIsShowBackBtn(!menuPaths.includes(currentRoute.path));
    }
  }, [currentRoute]);

  const handleBack = () => {
    navigate(-1);
  };

  const mapMenu = (menus: MenuItem[]): any => {
    const newMenu: any = [];
    const map = (menuItems: MenuItem[]) => {
      menuItems.forEach((item) => {
        if (Array.isArray(item.children) && item.children.length > 0) {
          map(item.children);
        }
        newMenu.push(item.path);
      });
    };
    map(menus);
    return newMenu;
  };

  return (
    <span className={styles.navbar}>
      { isShowBackBtn && <Button size="small" type="primary" ghost shape="round" onClick={handleBack}>返回</Button>}
      <span className={styles.pageTitle}>{currentRoute?.name}</span>
    </span>
  );
};

export default Navbar;
