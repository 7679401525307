/*
 * @Description:
 * @Author: likaifeng
 * @Date: 2023-11-21 18:29:46
 * @LastEditTime: 2023-11-28 19:34:24
 * @LastEditors: likaifeng
 */
import request from '@/common/request';

const apiPrefix = '';

/**
 * @description: 搜索会诊列表
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpConsultationListPage = (params: any) => {
  return request.post(`${apiPrefix}/consultation/listPage`, params);
};

/**
 * @description: 获取腾讯会议信息
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpConsultationTencentConference = (meetingId: number | string) => {
  return request.post(`${apiPrefix}/consultation/tencentConference`, { meetingId });
};

/**
 * @description: 会诊详情
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpConsultationDetail = (id: number | string) => {
  return request.post(`${apiPrefix}/consultation/detail`, { id });
};

/**
 * @description: 患者列表
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpPatientInfo = (id: number | string) => {
  return request.post(`${apiPrefix}/patient/info`, { id });
};

/**
 * @description: 查询医生个人团队以及团队成员
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpMdtGetMdtTeam = (params: any) => {
  return request.post(`${apiPrefix}/mdt/getMdtTeam`, params);
};

/**
 * @description: 查询团队列表
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpMdtListPages = (params: any) => {
  return request.post(`${apiPrefix}/mdt/listPage`, params);
};

/**
 * @description: 一周会诊排班详情
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpAppointmentListPage = (params: any) => {
  return request.post(`${apiPrefix}/appointment/listPage`, params);
};

/**
 * @description: 查询医生详情（单个）
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpGetDoctorInfo = (params: any) => {
  return request.post(`${apiPrefix}/doctor/info`, params);
};

/**
 * @description: 医生列表分页
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpDoctorListPage = (params: any) => {
  return request.post(`${apiPrefix}/doctor/listPage`, params);
};

/**
 * @description: 查询团队详情（单个）
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpMdtTeamDetails = (params: any) => {
  return request.post(`${apiPrefix}/mdt/teamDetails`, params);
};

/**
 * @description: 医生会诊建议列表分页
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpAdviceListPage = (params: any) => {
  return request.post(`${apiPrefix}/advice/listPage`, params);
};

/**
 * @description: MDT结论
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpConclusionListPage = (params: any) => {
  return request.post(`${apiPrefix}/conclusion/listPage`, params);
};

/**
 * @description: 取消会诊
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpConclusionCancel = (params: any) => {
  return request.post(`${apiPrefix}/consultation/cancel`, params);
};

/**
 * @description: 新增会诊
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpConsultationAdd = (params: any) => {
  return request.post(`${apiPrefix}/consultation/add`, params);
};

/**
 * @description: 根据团队ID数组患者列表
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpPatientListPageByTeam = (params: any) => {
  return request.post(`${apiPrefix}/patient/listPageByTeam`, params);
};

/**
 * @description: 会诊详情中再添加患者
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpConsultationPatientAdd = (params: any) => {
  return request.post(`${apiPrefix}/consultationPatient/add`, params);
};

/**
 * @description: 会诊创建中再邀请团队
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpConsultationCreation = (params: any) => {
  return request.post(`${apiPrefix}/consultation/creation`, params);
};

/**
 * @description: 获取当前会诊医生团队
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpConsultationGetTeamDoctor = (params: any) => {
  return request.post(`${apiPrefix}/consultation/getTeamDoctor`, params);
};

/**
 * @description: 添加MDT讨论
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpAdviceAdd = (params: any) => {
  return request.post(`${apiPrefix}/advice/add`, params);
};

/**
 * @description: 更新MDT讨论
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpAdviceUpdate = (params: any) => {
  return request.post(`${apiPrefix}/advice/update`, params);
};

/**
 * @description: 删除MDT讨论
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpAdviceDelete = (params: any) => {
  return request.post(`${apiPrefix}/advice/delete`, params);
};

/**
 * @description: 添加MDT结论
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpConclusionAdd = (params: any) => {
  return request.post(`${apiPrefix}/conclusion/add`, params);
};

/**
 * @description: 更新MDT结论
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpConclusionUpdate = (params: any) => {
  return request.post(`${apiPrefix}/conclusion/update`, params);
};

/**
 * @description: 删除MDT结论
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpConclusionDelete = (params: any) => {
  return request.post(`${apiPrefix}/conclusion/delete`, params);
};

/**
 * @description: 结束会诊
 * @author: likaifeng
 * @param {any} params
 * @return {*}
 * @Date: 2023-11-17 10:50:47
 */
export const httpConsultationUpdate = (params: any) => {
  return request.post(`${apiPrefix}/consultation/update`, params);
};
