/*
 * @Description: 讲稿演示-主要内容-出院记录
 * @Author: likaifeng
 * @Date: 2023-11-30 17:09:45
 * @LastEditTime: 2023-12-04 14:25:25
 * @LastEditors: likaifeng
 */
import { speechFilterMakeDataAtom } from '@/store/lecture';
import React from 'react';
import { useRecoilValue } from 'recoil';
import SpeechCard from '../SpeechCard';
import SpeechField from '../SpeechField';
import SpeechStep from '../SpeechStep';
import SpeechStepCard from '../SpeechStepCard';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-出院记录
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:25:20
 */
const SpeechDischarge: React.FC = () => {
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);

  return speechFilterMakeData?.hospitalDischargeRecords?.length ? (
    <SpeechCard titleId="hospitalDischargeRecords" title="出院记录">
      <div className={styles['speech-discharge']}>
        {speechFilterMakeData.hospitalDischargeRecords?.map((item: any) => (
          <SpeechStep
            key={item.id}
            id={`hospitalDischargeRecords_${item.id}`}
            time={`出院时间：${item.dischargeTime || '-'}`}
          >
            <SpeechStepCard
              padding="16px 16px 4px 16px"
              disabled={item.imagePath && item.imagePath.length}
            >
              <SpeechField
                title="报告图片"
                border={false}
                type="IMAGE"
                imagesSource={item.imagePath}
              />
            </SpeechStepCard>
            <SpeechStepCard
              disabled={
                item.admitTime ||
                item.admitDescription ||
                item.treatmentCourse ||
                item.dischargeDescription ||
                item.dischargeAdvice
              }
            >
              <SpeechField border title="入院时间" description={item.admitTime} />
              <SpeechField border title="入院情况" description={item.admitDescription} />
              <SpeechField border title="治疗经过" description={item.treatmentCourse} />
              <SpeechField border title="出院情况" description={item.dischargeDescription} />
              <SpeechField title="出院医嘱" border="false" description={item.dischargeAdvice} />
            </SpeechStepCard>
          </SpeechStep>
        ))}
      </div>
    </SpeechCard>
  ) : null;
};

export default SpeechDischarge;
