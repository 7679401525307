import { httpAgencyList } from '@/services/user';
import { getSystemUserInfo, setLocalStorage } from '@/utils/cookies';
import { CaretDownOutlined } from '@ant-design/icons';
import { Select } from '@sinohealth/butterfly-ui-components';
import { useRecoilState } from 'recoil';
import styles from './index.less';
import useCurrentLoginUserInfo from '@/hooks/useCurrentLoginUserInfo';
import { userInfoAtom } from '@/store/atom';

const Team = () => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const options = userInfo.agencys?.map((agencyItem: any) => {
    return {
      label: agencyItem.agencyName,
      value: agencyItem.agencyId,
    };
  });

  const handleChangeTeam = (agencyId: string) => {
    setUserInfo({
      ...userInfo,
      agency: userInfo.agencys.filter((item: any) => item.agencyId === agencyId)[0] || {},
    });
  };
  return (
    <div className={styles.team}>
      <div className={styles.left}>当前访问机构</div>
      <div className={styles.right}>
        <Select
          onSelect={handleChangeTeam}
          value={userInfo.agency.agencyId}
          dropdownMatchSelectWidth={false}
          bordered={false}
          suffixIcon={<CaretDownOutlined style={{ color: 'rgba(11, 33, 69, 0.65)', pointerEvents: 'none' }} />}
          options={options}
        />
      </div>
    </div>
  );
};

export default Team;
