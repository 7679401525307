/*
 * @Description: 讲稿演示-主要内容-患者信息
 * @Author: likaifeng
 * @Date: 2023-11-30 16:12:41
 * @LastEditTime: 2023-12-13 10:37:48
 * @LastEditors: likaifeng
 */
import { speechFilterMakeDataAtom } from '@/store/lecture';
import { Popover } from '@sinohealth/butterfly-ui-components';
import React from 'react';
import { useRecoilValue } from 'recoil';
import SpeechCard from '../SpeechCard';
import styles from './index.less';

/**
 * @description: 讲稿演示-主要内容-患者信息
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-04 14:27:51
 */
const SpeechPatient: React.FC = () => {
  const speechFilterMakeData = useRecoilValue(speechFilterMakeDataAtom);

  return (
    <SpeechCard id="baseInfo" title="患者信息">
      <div className={styles['speech-patient']}>
        <div className={styles.container}>
          <ul className={styles.patient}>
            <li className={styles['patient-item']}>
              <span className={styles['item-label']}>姓名</span>
              <span className={styles['item-name']}>
                {speechFilterMakeData?.baseInfo?.name || '未填写'}
              </span>
            </li>
            <li className={styles['patient-item']}>
              <span className={styles['item-label']}>性别</span>
              <span className={styles['item-name']}>
                {speechFilterMakeData?.baseInfo?.gender || '未填写'}
              </span>
            </li>
            <li className={styles['patient-item']}>
              <span className={styles['item-label']}>年龄</span>
              <span className={styles['item-name']}>
                {speechFilterMakeData?.baseInfo?.age || '未填写'}
              </span>
            </li>
            <li className={styles['patient-item']}>
              <span className={styles['item-label']}>医院</span>
              <Popover content={speechFilterMakeData?.baseInfo?.hospitalName || '未填写'}>
                <span className={styles['item-name']}>
                  {speechFilterMakeData?.baseInfo?.hospitalName || '未填写'}
                </span>
              </Popover>
            </li>
            <li className={styles['patient-item']}>
              <span className={styles['item-label']}>肿瘤分期</span>
              <span className={styles['item-name']}>
                {speechFilterMakeData?.baseInfo?.tumorStage || '未填写'}
              </span>
            </li>
          </ul>
          <div className={styles.footer}>
            <span className={styles['footer-label']}>最新诊断</span>
            <Popover content={speechFilterMakeData?.baseInfo?.latestDiagnosis || '未填写'}>
              <span className={styles['footer-desc']}>
                {speechFilterMakeData?.baseInfo?.latestDiagnosis || '未填写'}
              </span>
            </Popover>
          </div>
        </div>
      </div>
    </SpeechCard>
  );
};

export default SpeechPatient;
