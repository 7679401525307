import Empty from '@/components/Empty';
import theme from '@/config/theme';
import '@/style/iconfont/iconfont.css';
import zhCN from '@sinohealth/butterfly-ui-antd/lib/locale/zh_CN';
import { ConfigProvider, Spin } from '@sinohealth/butterfly-ui-components/lib';
import '@sinohealth/butterfly-ui-components/lib/index.css';
import moment from 'moment';
import 'moment/locale/zh-cn';
import { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import App from './App';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import 'dayjs/locale/zh-cn';

dayjs.extend(tz);
dayjs.extend(utc);
dayjs.tz.setDefault('Asia/Shanghai');
process.env.TZ = 'Asia/Shanghai';

moment.locale('zh-cn');

const loading = (
  <div style={{ display: 'flex', height: '100vh' }}>
    <Spin size="large" style={{ margin: 'auto' }} />
  </div>
);
ReactDOM.render(
  <ConfigProvider theme={theme} locale={zhCN} renderEmpty={Empty}>
    <RecoilRoot>
      <Suspense fallback={loading}>
        <HashRouter>
          <App />
        </HashRouter>
      </Suspense>
    </RecoilRoot>
  </ConfigProvider>,
  document.getElementById('root'),
);
