/*
 * @Description: 讲稿预览-统计时间
 * @Author: likaifeng
 * @Date: 2023-12-05 16:50:06
 * @LastEditTime: 2023-12-05 18:08:27
 * @LastEditors: likaifeng
 */
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import styles from './index.less';

/**
 * @description: 讲稿预览-统计时间
 * @author: likaifeng
 * @return {*}
 * @Date: 2023-12-05 16:55:42
 */
const SpeechStopWatch: React.FC = () => {
  const [elapsed, setElapsed] = useState('00:00:00');
  const [closeFlag, setCloseFlag] = useState(true);
  const [isStart, setIsStart] = useState(false);
  const [timerInstance, setTimerInstance] = useState<any>(null);
  const [time, setTime] = useState(0);

  let tt = 0;

  /**
   * @description: 轮训时间
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-12-05 17:43:14
   */
  const looping = () => {
    const timer = setInterval(() => {
      // eslint-disable-next-line no-plusplus, no-const-assign
      tt += 1;
      setTime(tt);
      formatDuration(tt);
    }, 1000);
    setTimerInstance(timer);
  };

  /**
   * @description: 倒计时
   * @author: likaifeng
   * @param {number} secs
   * @return {*}
   * @Date: 2023-12-05 17:43:04
   */
  const formatDuration = (secs: number) => {
    let sec = secs;
    // console.log(sec);
    const padString = (n: number) => (n < 10 ? `0${n.toFixed(0)}` : n.toFixed(0));

    const hh = Math.floor(sec / 3600);
    sec -= 3600 * hh;
    const mm = Math.floor(sec / 60);
    sec -= 60 * mm;
    const ss = Math.floor(sec);

    const tip = `${padString(hh)}:${padString(mm)}:${padString(ss)}`;
    setElapsed(tip);
  };

  /**
   * @description: 开始/暂停
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-12-05 17:42:50
   */
  const handleStartStopWatch = () => {
    if (isStart) {
      setIsStart(false);
      tt = time;
      looping();
    } else {
      setIsStart(true);
      clearInterval(timerInstance);
    }
  };

  /**
   * @description: 重置
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-12-05 17:43:24
   */
  const handleResetStopWatch = () => {
    setTime(0);
    clearInterval(timerInstance);
    setElapsed('00:00:00');
    looping();
  };

  /**
   * @description: 关闭
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-12-05 17:43:32
   */
  const handleCloseStopWatch = () => {
    setTime(0);
    clearInterval(timerInstance);
    setElapsed('00:00:00');
    setCloseFlag(!closeFlag);
    // looping();
  };

  useEffect(() => {
    looping();

    return () => {
      clearInterval(timerInstance);
      setTime(0);
    };
  }, []);

  return (
    <div className={classNames(styles['stop-watch'], closeFlag && styles['close-watch'])}>
      <div
        className={classNames(styles['watch-icon'])}
        onClick={() => {
          if (!closeFlag) {
            looping();
            setCloseFlag(!closeFlag);
          }
        }}
      />
      {closeFlag ? (
        <>
          <div className={classNames(styles.time)}>{elapsed}</div>
          <div className={classNames(styles['watch-pause'])} onClick={handleStartStopWatch}>
            {isStart ? '开始' : '暂停'}
          </div>
          <div className={classNames(styles['watch-reset'])} onClick={handleResetStopWatch}>
            重置
          </div>
          <div className={classNames(styles['watch-close'])} onClick={handleCloseStopWatch}>
            关闭
          </div>
        </>
      ) : (
        <div className={classNames(styles['watch-timekeeping'])}>计时</div>
      )}
    </div>
  );
};

export default SpeechStopWatch;
