import { download } from '@/services';
import { useEffect, useState } from 'react';

/**
 * @description: 处理文件下载
 * @author: likaifeng
 * @param {any} rootSrc
 * @return {*}
 * @Date: 2023-12-03 18:31:07
 */
export const useFetchImage = (rootSrc: any = '') => {
  const [originUrl, setOriginUrl] = useState<any>(rootSrc);
  const [intactFileUlr, setIntactFileUlr] = useState('');

  useEffect(() => {
    if (originUrl && typeof originUrl === 'string') {
      httpDowbloadImage().catch(() => {
        // 失败后查多一次，接口不是很稳定
        httpDowbloadImage();
      });
    } else {
      setIntactFileUlr(originUrl);
    }
  }, [originUrl]);

  /**
   * @description: 下载文件
   * @author: likaifeng
   * @return {*}
   * @Date: 2023-12-03 18:30:48
   */
  const httpDowbloadImage = () => {
    return download(originUrl).then((res: any) => {
      setIntactFileUlr(res);
    });
  };
  return { intactFileUlr, setOriginUrl };
};

export default useFetchImage;
